<template>
    <div class="d-block d-lg-none">
        <div class="row">
            <div class="col-10 col-sm-9 mb-0 col-lg-10">
                <p class="myinfoMobile" style="margin-left: 20px;">My Info</p>
            </div>
            <div class="col-2 col-sm-3 col-lg-2">
                <p class="closeButton" @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
            </div>
        </div>
        <div class="hr-border"></div>
    </div>
    <div class="mobileStylePublic">
        <div class="info w-100">
            <fieldset class="curve-box">
                <legend class="subHeadingText">About me</legend>
                
                <div class="row my-2">
                    <div class="col-12 col-md-6 col-lg-6 col-sm-6">
                        <label>First name</label>
                        <input type="text" class="form-control cursor UpdateTextAlignment" :disabled="true" v-model="userRecords.firstName">
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-sm-6">
                        <label>Last name</label>
                        <input type="text" class="form-control cursor UpdateTextAlignment" :disabled="true" v-model="userRecords.lastName">
                    </div>
                </div>
                <!-- end row -->
                <div class="row my-2">
                    <div class="col-6">
                        <label>Age</label>
                        <input type="number" class="form-control cursor UpdateTextAlignment" :disabled="true" v-model="userRecords.age">
                    </div>
                    <div class="col-6">
                        <label>Gender</label>
                        <input type="gender" class="form-control cursor UpdateTextAlignment" :disabled="true" v-model="userRecords.gender">
                    </div>
                </div>
            </fieldset>
            <!-- end row -->
            <fieldset class="curve-box">
                <legend class="subHeadingText d-block d-lg-none">My contact info</legend>
                <div class="row my-2">
                    <div class="col-lg-5 col-md-5 col-8">
                        <div class="form-group">
                            <label>Mobile number</label>
                            <input type="Number" id="primary_phone_number" name="primary_phone_number" class="form-control cursor UpdateTextAlignment"
                                v-model="userRecords.mobile" :disabled="true" />

                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-4" style="margin-top:15px">
                        <button type="button" @click="addNumber = true" style="font-size:30px;border-color: transparent;cursor: auto;" id="btn_phone_add"
                            class="btn btn_add"><img src="@/assets/images/verify.png" style="width:50px ; " /></button>
                    </div>
                    <!-- <div class="col-2 col-md-1" style="margin-top:15px">
                <button type="button" @click="addNumber = true" style="font-size:30px" id="btn_phone_add"
                    class="btn btn_add"><font-awesome-icon :icon='["fas", "plus-circle"]' /></button>
            </div> -->
                    <div class="col-lg-5 col-md-5">
                        <div class="form-group primarystyle">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" v-model="primaryRadio"
                                    name="primary_phone_check" id="primary_phone_check" :value="true"
                                    style="margin-left:5px;">
                                <label class="form-check-label" for="primary_phone_check"
                                    style="margin-left:7px">Primary</label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group my-2">
                        <div>
                            <label>Select a messaging method</label>
                        </div>
                        <div class="form-check form-check-inline mobileDisplayCommunication" v-for="value in Communication"
                            :key="value">
                            <input class="form-check-input" type="radio" name="communication" :id="value.id"
                                :value="value.id" v-model="communicationStatus" @click="getContact(id)"
                                v-bind:class="[enableAllFields === true ? 'hide-cursor' : '']" :disabled="enableAllFields">
                            <label class="form-check-label" for="communication">{{ value.communication }}</label>
                        </div>
                    </div>
                </div>

                <div class="row my-2">
                    <div class="col-lg-5 col-md-5 col-8">
                        <div class="form-group">
                            <label>Mail ID <span class="star">*</span></label>
                            <input type="email" @input="handleEmailInput" v-bind:class="[enableAllFields === true ? 'hide-cursor' : '']"
                                v-model="userRecords.email" id="email" name="email" class="form-control UpdateTextAlignment"
                                :disabled="enableAllFields"/>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-4">
                        <button type="button" class="btn btn-blue-color" :disabled="true" @click="verifyEmail"
                            style="color: white; background: #cccccc !important;margin-top:23px;pointer-events: none;">Verify</button>
                    </div>
                    <!-- <div class="col-2 col-md-1" style="margin-top:15px">
                <button type="button" @click="addEmail = true" style="font-size:30px" id="btn_homeconsult_add"
                    class="btn btn_add"><font-awesome-icon :icon='["fas", "plus-circle"]' /></button>
            </div> -->
                    <div class="col-lg-5 col-md-5">
                        <div class="form-group primarystyle">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" v-model="emailRadio"
                                    name="primary_email_check" id="primary_email_check" :value="true"
                                    style="margin-left:10px;">
                                <label class="form-check-label" for="primary_email_check"
                                    style="margin-left:7px">Primary</label>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
            <div class="mt-3">
                <button class="btn btn-blue-color text-white" :disabled="click" @click="saveUserData()">Add</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            addNumber: false,
            addEmail: false,
            userPayload: {},
            click: false,
            primaryRadio: false,
            emailRadio:false,
            Communication: [],
            userRecords: {},
            publicUserID: {},
            enableAllFields: false,
        }
    },
    created: function () {
        this.getUserDetail();
        this.getCommunication();
    },
    computed: {
        
        isEmailValid() {
            // Check if the email is valid using a regular expression
            const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            return emailRegex.test(this.userRecords.email);
        },
    },
    methods: {
        getDisplayPublicValue(index) {
      return `${index.id} - 1`;
    },
        handleEmailInput() {
         this.userRecords.email = this.userRecords.email.toLowerCase();
    },
        GoSetting() {
            window.scrollTo(0, 0);
            this.$router.push(`/user/mobile-setting`);
        },
        async getCommunication() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            await axios
                .get(`${BASE_API_URL}/public-user/communication`)
                .then((response) => {
                    if (response?.data) {
                        this.Communication = response.data
                    }
                })
        },
        getContact() {
            this.userPayload.communication = this.select;
        },

        async getUserDetail() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            this.publicUserID = localStorage.getItem('publicSignUpData');
            axios
                .get(`${BASE_API_URL}/public-user/${this.publicUserID}/one`)
                .then((response) => {
                    if (response?.data) {
                        this.userRecords = response.data;
                        this.communicationStatus = this.userRecords?.communication?.id
                        this.primaryRadio = true
                        this.enableAllFields = false
                        this.emailRadio = this.userRecords?.emailCheck
                    }
                })
                .catch(function (error) {
                    console.log(error?.response);
                });
        },
        async saveUserData() {
            this.userRecords.communicationStatus = this.communicationStatus

            if (!this.communicationStatus) {
                this.$swal("Please select a messaging method");
                return
            }
            if (!this.userRecords.email) {
                this.$swal("Please enter the email ID!");
                return;
            }
            if (!this.emailValidation(this.userRecords.email)) {
                return false;
            }
            this.click = true
            this.enableAllFields = true
            let updatePayload = {
                firstName: this.userRecords.firstName,
                lastName: this.userRecords.lastName,
                age: this.userRecords.age,
                gender: this.genderStatus,
                mobile: this.userRecords.mobile,
                email: this.userRecords.email,
                communication: this.communicationStatus,
                emailCheck:this.emailRadio
            };
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            await axios
                .put(`${BASE_API_URL}/public-user/${localStorage.getItem('publicSignUpData')}/update`, updatePayload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response?.data) {
                        // localStorage.setItem('id', response?.data.id)
                        this.userRecords = response.data;
                        this.$swal('Profile updated successfully')
                    }
                })
                .catch(function (error) {
                    console.log(error?.response);
                });
        },
        emailValidation(inputtxt) {
            var email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            var consecutiveSpecialChar = /[\W]{2}/;

            if (inputtxt.match(email) && !consecutiveSpecialChar.test(inputtxt)) {
                return true;
            } else {
                this.$swal("Please enter the correct email ID!");
                return false;
            }
        },
    }
}
</script>
<style>
.hide-cursor{
    cursor: not-allowed;
}
.UpdateTextAlignment{
    text-align: left !important;
}
.primarystyle {
    margin-top: 35px;
}
@media screen and (max-width:991px) {
    .mobileStylePublic {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 6rem;
    }
    .myinfoMobile {
        margin-left: 20px;
        /* margin-bottom: 10px; */
        margin-top: 1rem;
        color: #000;
        font-weight: 550;
        font-size: 21px;
    }

    fieldset.curve-box {
        border: 2px solid #00979e;
        border-radius: 20px;
        padding: 15px;
        margin-top: 1.5rem;
    }

    legend.subHeadingText {
        background: #fff;
        padding: 4px 10px;
        /* margin: 0 auto; */
        display: block;
        float: none;
        width: auto;
        font-size: 20px;
    }
}

@media screen and (max-width:767px) {
    .mobileDisplayCommunication {
        display: block;
    }

    .primarystyle {
        margin-top: 0px;
    }
}
</style>